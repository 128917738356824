import { BigNumber } from 'bignumber.js';
import tokens from '~/public/img/tokens';
import abi from '@/public/abi/abi.json';

export enum Tokens {
  pearl = 'pearl',
  gold = 'gold',
  goldOre = 'goldOre',
  coal = 'coal',
  iron = 'iron',
  ironOre = 'ironOre',
  palladium = 'palladium',
  platina = 'platina',
  platinaOre = 'platinaOre',
  rhodium = 'rhodium',
  silver = 'silver',
  silverOre = 'silverOre',
  wood = 'wood',
  hammer = 'hammer',
  nail = 'nail',
  heatinglog = 'heatinglog',
  woodenPlank = 'woodenPlank',
  blackPearl = 'blackPearl',
  earrings = 'earrings',
  necklaces = 'necklaces',
  rings = 'rings',
  ships = 'ships',
  palms = 'palms',
  chairs = 'chairs',
  acorn = 'acorn',
  shiningMetalite = 'shiningMetalite',
  crystallineOre = 'crystallineOre',
  jewelryBoxes = 'jewelryBoxes',
  barrelsOfRum = 'barrelsOfRum',
  pickaxe = 'pickaxe',
  shovel = 'shovel',
  saw = 'saw',
  bed = 'bed',
  table = 'table',
  broom = 'broom',
  leaf = 'leaf',
  metalJoin = 'metalJoin',
  woodenHuts = 'woodenHuts',
  glass = 'glass',
  brick = 'brick',
  clay = 'clay',
  sand = 'sand',

  seasonPoints = 'seasonPoints'
}

const erc20TokensEntries: [Tokens, (typeof abi)['erc20']][] = Object.values(Tokens).map((item) => {
  return [item, abi.erc20];
});

export const CONTRACT_ABI = Object.assign(
  Object.fromEntries(erc20TokensEntries) as Record<Tokens, (typeof abi)['erc20']>,
  {
    pearl: abi.erc20,
    gold: abi.erc20,
    goldOre: abi.erc20,
    coal: abi.erc20,
    iron: abi.erc20,
    ironOre: abi.erc20,
    palladium: abi.erc20,
    platina: abi.erc20,
    platinaOre: abi.erc20,
    rhodium: abi.erc20,
    silver: abi.erc20,
    silverOre: abi.erc20,
    wood: abi.erc20,
    acorns: abi.erc20,
    woodenPlank: abi.erc20,
    hammer: abi.erc20,
    nail: abi.erc20,
    acorn: abi.erc20,
    shiningMetalite: abi.erc20,
    crystallineOre: abi.erc20,
    heatinglog: abi.erc20,
    usdt: abi.erc20,

    pearlStore: abi.pearlStore,
    mine: abi.mine,

    pearlYield: abi.pearlYield,
    goldYield: abi.goldYield,

    blackPearl: abi.erc20,
    blackPearlVesting: abi.blackPearlVesting,
    earrings: abi.erc721,
    necklaces: abi.erc721,
    rings: abi.erc721,
    ships: abi.erc721,
    palms: abi.erc721,
    chairs: abi.erc721,
    jewelryBoxes: abi.erc721,
    barrelsOfRum: abi.erc721,
    broom: abi.erc20,
    leave: abi.erc20,
    metalJoin: abi.erc20,
    woodenHuts: abi.erc721,

    erc20: abi.erc20,
    erc721: abi.erc721,
    map: abi.map,
    crafting: abi.crafting,
    seasonPoints: abi.erc20,
    season: abi.season,
    SEASON_REWARDS_YEAR_1: abi.SEASON_REWARDS_YEAR_1,
    shop: abi.shop
  }
);

export enum TokenUniquenessType {
  Common = 'common',
  Uncommon = 'uncommon',
  Rare = 'rare'
}

export type MiningsTableRow = {
  amount: BigNumber;
  createdAt: string;
  available: string;
};

export type ExtraReward = {
  label: string;
  media: string;
};

export type TTokensConfigItem = {
  name: Record<string, keyof typeof CONTRACT_ABI | 'usdt'>;
  media: string;
  label: string;
  methodName: string;
  extraReward?: ExtraReward;
  rate?: number;
  interface: 'erc20' | 'erc721';
};

export type TTokensConfig = Record<Tokens | 'usdt', TTokensConfigItem>;

export const tokensConfig: TTokensConfig = {
  usdt: {
    name: {
      contract: 'usdt'
    },
    methodName: 'USDT',
    label: 'usdt',
    media: '/img/icons/usdt.svg',
    interface: 'erc20'
  },
  [Tokens.pearl]: {
    name: {
      contract: Tokens.pearl,
      store: 'pearlStore',
      yield: 'pearlYield'
    },
    rate: 0.01,
    methodName: 'Pearl',
    label: 'corePearl',
    media: '/img/tokens/pearl.png',
    extraReward: {
      label: 'dashboardBlackPearls',
      media: '/img/tokens/blackPearl.svg'
    },
    interface: 'erc20'
  },
  [Tokens.gold]: {
    name: {
      contract: 'gold',
      yield: 'goldYield'
    },
    label: 'coreGold',
    methodName: 'Gold',
    media: '/img/tokens/gold.png',
    interface: 'erc20'
  },
  [Tokens.goldOre]: {
    name: {
      contract: 'goldOre'
    },
    rate: 0.5,
    label: 'coreGoldOre',
    methodName: 'Gold Ore',
    media: '/img/tokens/goldOre.png',
    extraReward: {
      label: 'coreShiningMetallite',
      media: '/img/tokens/shining_metallite.png'
    },
    interface: 'erc20'
  },
  [Tokens.coal]: {
    name: {
      contract: 'coal'
    },
    rate: 0.001,
    label: 'coreCoal',
    methodName: 'Coal',
    media: '/img/tokens/coal.png',
    extraReward: {
      label: 'coreCrystallineOre',
      media: '/img/tokens/crystalline_ore.png'
    },
    interface: 'erc20'
  },
  [Tokens.iron]: {
    name: {
      contract: 'iron'
    },
    label: 'coreIron',
    methodName: 'Iron',
    media: '/img/tokens/iron.png',
    interface: 'erc20'
  },
  [Tokens.ironOre]: {
    name: {
      contract: 'ironOre'
    },
    rate: 0.015,
    extraReward: {
      label: 'coreShiningMetallite',
      media: '/img/tokens/shining_metallite.png'
    },
    label: 'coreIronOre',
    methodName: 'Iron Ore',
    media: '/img/tokens/ironOre.png',
    interface: 'erc20'
  },
  [Tokens.shiningMetalite]: {
    name: {
      contract: 'shiningMetalite'
    },
    label: 'coreShiningMetallite',
    methodName: 'Shining Metallite',
    media: '/img/tokens/shining_metallite.png',
    interface: 'erc20'
  },
  [Tokens.crystallineOre]: {
    name: {
      contract: 'crystallineOre'
    },
    label: 'coreCrystallineOre',
    methodName: 'Crystallite Ore',
    media: '/img/tokens/crystalline_ore.png',
    interface: 'erc20'
  },
  [Tokens.palladium]: {
    name: {
      contract: 'palladium'
    },
    label: 'corePalladium',
    methodName: 'Palladium',
    media: '/img/tokens/palladium.png',
    interface: 'erc20'
  },
  [Tokens.platina]: {
    name: {
      contract: 'platina'
    },
    label: 'corePlatina',
    methodName: 'Platina',
    media: '/img/tokens/platina.png',
    interface: 'erc20'
  },
  [Tokens.platinaOre]: {
    name: {
      contract: 'platinaOre'
    },
    label: 'corePlatinaOre',
    methodName: 'Platina Ore',
    media: '/img/tokens/platinaOre.png',
    interface: 'erc20'
  },
  [Tokens.rhodium]: {
    name: {
      contract: 'rhodium'
    },
    label: 'coreRhodium',
    methodName: 'Rhodium',
    media: '/img/tokens/rhodium.png',
    interface: 'erc20'
  },
  [Tokens.silver]: {
    name: {
      contract: 'silver'
    },
    label: 'coreSilver',
    methodName: 'Silver',
    media: '/img/tokens/silver.png',
    interface: 'erc20'
  },
  [Tokens.silverOre]: {
    name: {
      contract: 'silverOre'
    },
    label: 'coreSilverOre',
    methodName: 'Silver Ore',
    media: '/img/tokens/silverOre.png',
    interface: 'erc20'
  },
  [Tokens.wood]: {
    name: {
      contract: 'wood'
    },
    extraReward: {
      label: 'coreAcorns',
      media: '/img/tokens/acorn.png'
    },

    rate: 0.005,
    label: 'coreWood',
    methodName: 'Wood',
    media: '/img/tokens/wood.png',
    interface: 'erc20'
  },
  [Tokens.woodenPlank]: {
    name: {
      contract: 'woodenPlank'
    },
    label: 'coreWoodenPlank',
    methodName: 'Wooden Plank',
    media: '/img/tokens/woodenPlank.png',
    interface: 'erc20'
  },
  [Tokens.hammer]: {
    name: {
      contract: 'hammer'
    },
    label: 'coreSimpleHammer',
    methodName: 'Hammer',
    media: '',
    interface: 'erc20'
  },
  [Tokens.acorn]: {
    name: {
      contract: 'acorn'
    },
    label: 'coreAcorns',
    methodName: 'Acorn',
    media: '',
    interface: 'erc20'
  },
  [Tokens.nail]: {
    name: {
      contract: 'nail'
    },
    label: 'coreNails',
    methodName: 'Nails',
    media: '',
    interface: 'erc20'
  },
  [Tokens.heatinglog]: {
    name: {
      contract: 'heatinglog'
    },
    label: 'coreHeatingLogs',
    methodName: 'Heating Log',
    media: '',
    interface: 'erc20'
  },
  [Tokens.blackPearl]: {
    name: {
      contract: 'blackPearl'
    },
    label: 'coreBlackPearl',
    methodName: 'Black Pearl',
    media: '/img/icons/blackPearl.svg',
    interface: 'erc20'
  },
  [Tokens.seasonPoints]: {
    name: {
      contract: 'seasonPoints'
    },
    label: 'seasonPoints',
    methodName: 'Season Points',
    media: tokens.seasonPoints,
    interface: 'erc20'
  },
  [Tokens.pickaxe]: {
    name: {
      contract: Tokens.pickaxe
    },
    label: 'pickaxe',
    methodName: 'Pickaxe',
    media: '',
    interface: 'erc20'
  },
  [Tokens.shovel]: {
    name: {
      contract: Tokens.shovel
    },
    label: 'shovel',
    methodName: 'Shovel',
    media: '',
    interface: 'erc20'
  },
  [Tokens.saw]: {
    name: {
      contract: Tokens.saw
    },
    label: 'saw',
    methodName: 'Saw',
    media: '',
    interface: 'erc20'
  },
  [Tokens.bed]: {
    name: {
      contract: Tokens.bed
    },
    label: 'bed',
    methodName: 'Bed',
    media: '',
    interface: 'erc20'
  },
  [Tokens.table]: {
    name: {
      contract: Tokens.table
    },
    label: 'table',
    methodName: 'Table',
    media: '',
    interface: 'erc20'
  },
  [Tokens.broom]: {
    name: {
      contract: Tokens.broom
    },
    label: 'coreBroom',
    methodName: 'Broom',
    media: '',
    interface: 'erc20'
  },
  [Tokens.leaf]: {
    name: {
      contract: Tokens.leaf
    },
    label: 'coreLeave',
    methodName: 'Leaves',
    media: '',
    interface: 'erc20'
  },
  [Tokens.metalJoin]: {
    name: {
      contract: Tokens.metalJoin
    },
    label: 'coreMetalJoin',
    methodName: 'Metal Join',
    media: '',
    interface: 'erc20'
  },
  [Tokens.glass]: {
    name: {
      contract: Tokens.glass
    },
    label: 'coreGlass',
    methodName: 'Glass',
    media: '',
    interface: 'erc20'
  },
  [Tokens.brick]: {
    name: {
      contract: Tokens.brick
    },
    label: 'coreBrick',
    methodName: 'Brick',
    media: '',
    interface: 'erc20'
  },
  [Tokens.clay]: {
    name: {
      contract: Tokens.clay
    },
    extraReward: {
      label: 'coreGoldOre',
      media: '/img/tokens/goldOre.png'
    },
    rate: 0.0035,
    label: 'coreClay',
    methodName: 'Clay',
    media: '',
    interface: 'erc20'
  },
  [Tokens.sand]: {
    name: {
      contract: Tokens.sand
    },
    extraReward: {
      label: 'coreSand',
      media: '/img/tokens/sand.png'
    },
    rate: 0.0001,
    label: 'coreSand',
    methodName: 'Sand',
    media: '',
    interface: 'erc20'
  },

  [Tokens.earrings]: {
    name: {
      contract: 'earrings'
    },
    label: 'coreEarrings',
    methodName: 'Earrings',
    media: '',
    interface: 'erc721'
  },
  [Tokens.necklaces]: {
    name: {
      contract: 'necklaces'
    },
    label: 'coreNecklaces',
    methodName: 'Necklace',
    media: '',
    interface: 'erc721'
  },
  [Tokens.rings]: {
    name: {
      contract: 'rings'
    },
    label: 'coreRings',
    methodName: 'Ring',
    media: '',
    interface: 'erc721'
  },
  [Tokens.ships]: {
    name: {
      contract: 'ships'
    },
    label: 'nftChristmasShip',
    methodName: 'Ships',
    media: '',
    interface: 'erc721'
  },
  [Tokens.palms]: {
    name: {
      contract: 'palms'
    },
    label: 'nftChristmasPalm',
    methodName: 'Palms',
    media: '',
    interface: 'erc721'
  },

  [Tokens.chairs]: {
    name: {
      contract: 'chairs'
    },
    label: 'nftChair',
    methodName: 'Chair',
    media: '',
    interface: 'erc721'
  },

  [Tokens.jewelryBoxes]: {
    name: {
      contract: 'jewelryBoxes'
    },
    label: 'craftingRecipeJewelryBox2',
    methodName: 'Jewelry Boxes',
    media: '',
    interface: 'erc721'
  },

  [Tokens.barrelsOfRum]: {
    name: {
      contract: 'barrelsOfRum'
    },
    label: 'craftingRecipeBarrelOfRum1',
    methodName: 'Barrels of Rum',
    media: '',
    interface: 'erc721'
  },

  [Tokens.woodenHuts]: {
    name: {
      contract: 'woodenHuts'
    },
    label: 'nftHut',
    methodName: 'Wooden Hut',
    media: '',
    interface: 'erc721'
  }
};
