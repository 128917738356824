import { default as dashboardKIhr9Z9Xg1Meta } from "/workspace/pages/dashboard.vue?macro=true";
import { default as districtsQPM2sm9ttlMeta } from "/workspace/pages/districts.vue?macro=true";
import { default as gametmrEnJAR5ZMeta } from "/workspace/pages/game.vue?macro=true";
import { default as index9S03gOyIVcMeta } from "/workspace/pages/index.vue?macro=true";
import { default as marketplaceOHXB2te2S9Meta } from "/workspace/pages/marketplace.vue?macro=true";
import { default as profilePkbik2W1P0Meta } from "/workspace/pages/profile.vue?macro=true";
import { default as referrallfm4JwuN6hMeta } from "/workspace/pages/referral.vue?macro=true";
import { default as storyhm6x9597GQMeta } from "/workspace/pages/story.vue?macro=true";
export default [
  {
    name: "dashboard",
    path: "/dashboard",
    component: () => import("/workspace/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "districts",
    path: "/districts",
    meta: districtsQPM2sm9ttlMeta || {},
    component: () => import("/workspace/pages/districts.vue").then(m => m.default || m)
  },
  {
    name: "game",
    path: "/game",
    component: () => import("/workspace/pages/game.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: index9S03gOyIVcMeta || {},
    component: () => import("/workspace/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "marketplace",
    path: "/marketplace",
    meta: marketplaceOHXB2te2S9Meta || {},
    component: () => import("/workspace/pages/marketplace.vue").then(m => m.default || m)
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilePkbik2W1P0Meta || {},
    component: () => import("/workspace/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: "referral",
    path: "/referral",
    meta: referrallfm4JwuN6hMeta || {},
    component: () => import("/workspace/pages/referral.vue").then(m => m.default || m)
  },
  {
    name: "story",
    path: "/story",
    component: () => import("/workspace/pages/story.vue").then(m => m.default || m)
  }
]